<template lang="">
    <div>
        <Support/>
    </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
export default {
    components:{
        Support: () =>import('@/components/support/Support.vue')
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: 'Teknik Destek', route: '/teknik-destek' },
        ]);
    },
}
</script>
<style lang="">
    
</style>